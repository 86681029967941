var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c("CCardHeader", {
        directives: [
          {
            name: "t",
            rawName: "v-t",
            value: "pages.auth.ConfirmEmail.text",
            expression: "'pages.auth.ConfirmEmail.text'"
          }
        ],
        staticClass: "text-center lead"
      }),
      _c(
        "CCardBody",
        { staticClass: "text-center" },
        [
          _vm.errorMessage
            ? _c(
                "div",
                { staticClass: "alert alert-danger", attrs: { role: "alert" } },
                [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
              )
            : _vm._e(),
          _vm.loading ? _c("CSpinner", { attrs: { color: "info" } }) : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }